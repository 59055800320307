function getCookie(name) {
    let cookieValue = null;
    if (document.cookie && document.cookie !== '') {
        const cookies = document.cookie.split(';');
        for (let i = 0; i < cookies.length; i++) {
            const cookie = cookies[i].trim();
            // Does this cookie string begin with the name we want?
            if (name !== undefined && cookie.substring(0, name.length + 1) === (name + '=')) {
                cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                break;
            }
        }
    }
    return cookieValue;
}

const csrftoken = getCookie($('#csrf_cookie_name').val());

$(function () {

    $(document).ready(function () {
        init_function();
    });

    function init_function() {

        $("#password_reset_form").on('submit', (e) => {
            e.preventDefault();
            let form = $(e.currentTarget);
            $.ajax({
                type: form.attr('method'),
                url: form.attr('action'),
                data: form.serialize(),
                dataType: 'json',
                success: function (resp) {
                    $("#password_reset_modal").modal('hide');
                    infos.ajouterMessageToast({
                        message: resp.msg,
                        messageDuree: 1500,
                        icone: 'fa-check-circle-o',
                        iconeCouleur: 'green'
                    });
                },
                error: function (jqXHR) {
                    $('#email').html(jqXHR.responseJSON.email);
                }
            });
        });

    }
});
